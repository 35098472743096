interface SVGConfig {
  folder: string;
  icons: { [name: string]: string };
}

export const SVG_CONFIG: SVGConfig = {
  folder: '/assets/svg',
  icons: {
    'accepted-employers': 'accepted-employers',
    'alert-error': 'alert-error',
    'alert-info': 'alert-info',
    'alert-success': 'alert-success',
    'alert-warning': 'alert-warning',
    'applications': 'applications',
    'balances': 'balances',
    'cancel-action': 'cancel-action',
    'caret-left': 'caret-left',
    'check-circle': 'check-circle',
    'chevron-down': 'chevron-down',
    'chevron-left': 'chevron-left',
    'chevron-right': 'chevron-right',
    'chevron-up': 'chevron-up',
    'clients': 'clients',
    'close-x': 'close-x',
    'confirm-action': 'confirm-action',
    'dashboard': 'dashboard',
    'datepicker': 'datepicker',
    'delete-action': 'delete-action',
    'delete': 'delete',
    'dragdrop': 'dragdrop',
    'duplication-rules': 'duplication-rules',
    'edit-action': 'edit-action',
    'edit': 'edit',
    'eye-slash': 'eye-slash',
    'eye': 'eye',
    'keywords': 'keywords',
    'lead-providers': 'lead-providers',
    'plus': 'plus',
    'programs': 'programs',
    'question-mark': 'question-mark',
    'rules-management': 'rules-management',
    'scorecards-upload': 'scorecards-upload',
    'settings': 'settings',
    'trusted-banks': 'trusted-banks',
    'warning': 'warning',
  },
};
